/**
 * Copyright 2021, 2022 Kiwi Dreamer Consulting, LLC. All Rights Reserved.
 */

const config = {
    auth: {
      userPoolId: 'us-east-1_Kq22TyFHZ', 
      region: 'us-east-1',
      userPoolWebClientId: '1qbl9qetmh3hr8nu76ftcpudum', 
      authenticationFlowType: 'USER_PASSWORD_AUTH'
    },
    serviceRoot: 'https://backend.dev.dev.ab-net.us'
};

export default config;
