/**
 * Copyright 2021 Kiwi Dreamer Consulting, LLC. All Rights Reserved.
 */

import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#ffffff',
    },
    secondary: {
      main: '#121212',
    },
    background: {
      default: '##ffffff',
    },
    text: {
      primary: '#a12e32',
      secondary: '#000000',
      red: '#a12e32',
    },
    black: {
      main: '#121212'
    },
  },
  typography: {
    body1: {
      fontFamily: 'Open Sans',
    },
    body2: {
      fontFamily: 'Open Sans',
      color: '#FFFFFF',
    },
    h6: {
      color: '#FFFFFF',
    }
  },
});

export default theme;