/**
 * @class loginSlice
 * @author Eric Grabowski 
 * @description Storage class that provides a description and methods for login state
 * @copyright 2021, 2022 Kiwi Dreamer Consulting, LLC. All Rights Reserved.
 */

import { createSlice } from '@reduxjs/toolkit'

export const loginSlice = createSlice({
  name: 'isLoggedIn',
  initialState: {
    value: false,
    user: "",
    applist: []
  },
  reducers: {
    login: (state, action) => {
      state.value = true
      state.user = action.payload
    },
    logout: (state) => {
      state.value = false
      state.user = ""
      state.applist = []
    },
    setapplist: (state, action) => {
      state.applist = action.payload
    }
  },
})

export const { login, logout, setapplist } = loginSlice.actions

export default loginSlice.reducer