/**
 * @class LoadingView
 * @author Eric Grabowski 
 * @description View class that provides a progress bar
 * @copyright 2021, 2022 Kiwi Dreamer Consulting, LLC. All Rights Reserved.
 */

import React from 'react';
import '../../theme/Dce4bi.css';
import {Circular} from '../../components/progress/Circular';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

export default function LoadingView() {
    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: '100vh' }}>
            <Grid item xs={3}>
                <Typography align="center">
                    Starting Reporting Engine
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Circular/>
            </Grid>    
        </Grid> 
    )
}