/**
 * @class dashSlice
 * @author Eric Grabowski 
 * @description Storage class that provides a description and methods for dashboard state
 * @copyright 2021, 2022 Kiwi Dreamer Consulting, LLC. All Rights Reserved.
 */

import { createSlice } from '@reduxjs/toolkit'

const INITIAL_STATE = {
'viewname' : 'DCE Dashboard',
'view' : 0,
'enginestate' : false
}

export const dashSlice = createSlice({
  name: 'dashData',
  initialState: INITIAL_STATE,
  reducers: {

    resetdash: (state) => {
      state.viewname = INITIAL_STATE.viewname
      state.view = INITIAL_STATE.view
    },
    viewchange: (state, action) => {
      state.view = action.payload
  },
    viewnamechange: (state, action) => {
      state.viewname = action.payload
  },
  enginestatechange: (state, action) => {
    state.enginestate = action.payload
  },
  },
})

export const { resetdash, viewchange, viewnamechange, enginestatechange } = dashSlice.actions

export default dashSlice.reducer