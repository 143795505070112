const abierto = {
    'yellow': '#f3dd7f',
    'white': '#ffffff',    
    'grey': {
        100: '#e3e3e3',
        200: '#C2C2C2',
    },
    'black': '#000000',
  };
  
  export default abierto;