/**
 * @class Circular
 * @author Eric Grabowski 
 * @description A circular progress bar component
 * @copyright 2021, 2022 Kiwi Dreamer Consulting, LLC. All Rights Reserved.
 */

import { useState, useEffect } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

export function Circular() {
  const [level, setLevel] = useState(0);
  useEffect(() => {
    const timer = setInterval(() => {
      setLevel((newLevel) => (newLevel >= 100 ? 0 : newLevel + 10));
    }, 700);
    return () => {
      clearInterval(timer);
    };
  }, []);
  return (
    <Box sx={{ display: 'flex' }}>
      <CircularProgress variant="determinate" value={level}/>
    </Box>
  );
}