/**
 * Copyright 2021 Kiwi Dreamer Consulting, LLC. All Rights Reserved.
 */

import React from 'react';
import ReactDOM from 'react-dom';
import './theme/index.css';
import App from './App';
import { store } from './store/store.js'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { Circular } from './components/progress/Circular'
import { persistStore } from 'redux-persist';
import theme from './theme/theme';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, GlobalStyles} from "@mui/material";

let persistor = persistStore(store);

ReactDOM.render(

  <ThemeProvider theme={theme}>
    <CssBaseline />
    <GlobalStyles
          styles={{
            body: { backgroundColor: "primary" },
          }}
        />
    <Provider store={store}>
      <PersistGate loading={<Circular />} persistor={persistor}>
      <App />
      </PersistGate>
    </Provider>
  </ThemeProvider>,
  document.getElementById('root')
);


