/**
 * @class store
 * @author Eric Grabowski 
 * @description Redux store for persisting state data
 * @copyright 2021, 2022 Kiwi Dreamer Consulting, LLC. All Rights Reserved.
 */

import { configureStore } from '@reduxjs/toolkit'
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import storageSession from 'redux-persist/lib/storage/session'
import loginReducer from './loginSlice'
import dashReducer from './dashSlice'
import { combineReducers } from "redux"; 


const reducers = combineReducers({
    isLoggedIn: loginReducer,
    dashData: dashReducer       
   });

const persistConfig = {
  key: 'root',
  version: 1,
  storage: storageSession,
}

const persistedReducer = persistReducer(persistConfig, reducers)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
})

