/**
 * @class MainView
 * @author Eric Grabowski 
 * @description View class that provides Power BI embedded reports
 * @copyright 2021, 2022 Kiwi Dreamer Consulting, LLC. All Rights Reserved.
 */

import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';
import { models } from "powerbi-client";
import { PowerBIEmbed } from "powerbi-client-react";
import config from '../../config';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Typography from '@mui/material/Typography';
import { API } from 'aws-amplify';

export default function MainView() {
    const user = useSelector((state) => state.isLoggedIn.user);
    const [responseConfig, setResponseConfig] = useState({});
    const [qerr, setQerr] = useState(false);
    const [keyerr, setKeyerr] = useState('');
    const [rendered, setRendered] = useState(false);
    const key = 'CognitoIdentityServiceProvider.' + config.auth.userPoolWebClientId + '.' + user + '.idToken'; 
    const JWTToken = sessionStorage.getItem(key);

    const userDataKey = `CognitoIdentityServiceProvider.${config.auth.userPoolWebClientId}.${user}.userData`;
    const userData = JSON.parse(sessionStorage.getItem(userDataKey));
    const userAttributes = userData.UserAttributes;

    // eslint-disable-next-line
    const [report, setReport] = useState({});

    let client = '';

    for (var i = 0; i < userAttributes.length; i++) {
        if (userAttributes[i].Name === 'custom:client') {
            client = userAttributes[i].Value;
        }
    }

    function init() {
        if (rendered) {
            return;
        }

        setRendered(true);

        /**
         * @method fetchConfig
         * @description Authenticated API call that retrieves Power BI access token
         */
        const  fetchConfig = async () => {
            try {
                const apiName = 'dashboardapi';
                const path = '/token';
                const options = {
                    headers: {
                        "Authorization" : `Bearer ${JWTToken}`
                    },
                    queryStringParameters: {
                        client: client
                    }
                };
    
                const response = await API.get(apiName, path, options);
            
                console.log('GET call succeeded:', response);
                setResponseConfig(response);
                setQerr(false);
            }
            catch (e) {
                console.log('GET call failed: ', e);
                setQerr(true);
                setKeyerr(e);
            }
        };

        fetchConfig();
    }

    useEffect(() => {
        init();
    });

    return (
        <Box py={4} bgcolor='primary' alignItems="center" justifyContent="center" sx={{ border: '0px', alignItems: 'center' }}>
            {qerr ? (
                <Alert severity="error">
                    <AlertTitle>API Error</AlertTitle>
                    <Typography align="center">
                        Unable to retrieve BI report key data
                        <p />
                        {keyerr.message}
                    </Typography>
                </Alert>
            ) : (                  
            <PowerBIEmbed
                embedConfig={{
                    type: "report",
                    id: responseConfig.ReportId,
                    embedUrl: responseConfig.EmbedUrl,
                    accessToken: responseConfig.EmbedToken,
                    tokenType: models.TokenType.Embed,
                    settings: {
                        navContentPaneEnabled: true,
                        parameterPanel:{
                            enabled: true,
                            expanded: true
                        },
                        panes: {
                            filters: {
                                expanded: false,
                                visible: false,
                            },
                            pageNavigation: {
                                visible: true,
                            },
                        },
                        background: models.BackgroundType.Transparent,
                        zoomLevel: 1
                    },
                }}

                cssClassName = { "report-style-class" }

                getEmbeddedComponent = {(embedObject) => {
                    setReport(embedObject);
                }}
            />)}
        </Box>
    )
}