/**
 * @class Dashboard
 * @author Eric Grabowski 
 * @description Container class for displaying views
 * @copyright 2021, 2022 Kiwi Dreamer Consulting, LLC. All Rights Reserved.
 */

import React, { useState, useEffect }  from "react";
import MainView from './views/MainView';
import LoadingView from './views/LoadingView';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';
import abierto from '../theme/abierto';
import config from '../config';
import { API } from 'aws-amplify';

export default function Dashboard() {
    const enginestate = useSelector((state) => state.dashData.enginestate);
    const [engine, setEngine] = useState(enginestate);
    const [fetched, setFetched] = useState(false);
    const user = useSelector((state) => state.isLoggedIn.user);
    const key = 'CognitoIdentityServiceProvider.' + config.auth.userPoolWebClientId + '.' + user + '.idToken'; 
    const JWTToken = sessionStorage.getItem(key);

    const  fetchStatus = async () => {
        if (fetched) {
            return;
        }

        setFetched(true);

        try {
            const apiName = 'dashboardapi';
            const path = '/check';
            const options = {
                headers: {
                    "Authorization" : `Bearer ${JWTToken}`
                }
            };

            const response = await API.get(apiName, path, options);
        
            console.log('GET call succeeded:', response);
            setEngine(response)
        }
        catch (e) {
            console.log('GET call failed: ', e);
            setEngine(false);
        }
    };

    useEffect(() => {
        fetchStatus();
    });

    return (
        <Paper sx={{ backgroundColor: '#ffffff' }}>
            <Grid item xs={12}>
                <Box backgroundColor={abierto.white} display="flex" alignItems="center" justifyContent="center">
                    <Container maxWidth={false} >
                        <Box py={4} backgroundColor={abierto.white}>
                            {engine ? (<MainView />) : (<LoadingView />)}  
                        </Box>
                    </Container>
                </Box>
            </Grid>
        </Paper>
    )
}