/**
 * @class Login
 * @author Eric Grabowski 
 * @description Container class that provides a login screen
 * @copyright 2021, 2022 Kiwi Dreamer Consulting, LLC. All Rights Reserved.
 */

import React, { useState }  from "react";
import { useDispatch } from 'react-redux';
import { login } from '../store/loginSlice';
import { viewnamechange } from '../store/dashSlice';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { userLogin } from "../components/auth/Cognito";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';




function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://www.ab-net.us">
        Abierto Networks
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

export default function Login() {
  

  //Redux
  const dispatch = useDispatch()

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [logfail, setLogfail] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      await userLogin({ username, password });
      dispatch(login(username));
      dispatch(viewnamechange('Reporting Dashboard'));
      setLogfail(false);
    } catch (error) {
      setLogfail(true);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="username"
              autoFocus
              onChange={event => setUsername(event.currentTarget.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={event => setPassword(event.currentTarget.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              color='error'
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                {logfail ? (
                <Alert severity="error">
                  <AlertTitle>Login Failed</AlertTitle>
                  Please check your credentials and try again
                  </Alert>
                  ) : (null)}          
              </Grid>
            </Grid>
          </Box>        
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}